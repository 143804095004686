import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from './baseQueries';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: publicBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ payload, email, metaData, invitationToken, external, project_id }) => ({
        url: '/api/auth/login/',
        method: 'POST',
        body: {
          ...payload,
          wallet: {
            email,
            metaData,
          },
          invitationToken,
          external,
          project_id
        },
      }),
    })
  }),
});

export const { useLoginMutation } = authAPI;