import "./tradeFiOverview.scss";
import Typography from "../../../components/Typography/Typography";
import {
  useGetAllLendingPoolQuery,
  useGetAllLoanInfoQuery,
} from "../../../services/tradeFiService";
import { logEvent } from "../../../utils/amplitudeUtlis";
import { useNavigate } from "react-router-dom";
import TradeFiSummary from "./TradeFiSummary";

import PoolList from "./PoolList";
import Button from "../../../components/Button/Button";
import Loading from "../../../components/LoadingSkeleton/Loading";

export default function TradeFiOverviewPage() {
  const navigate = useNavigate();

  const { data: lendingPool, isLoading: isLendingLoading } =
    useGetAllLendingPoolQuery();
  const lendingData = lendingPool?.data;
  const { data: loanStatisticsData, isLoading: isLoanStatisticsLoading } =
    useGetAllLoanInfoQuery();

  const handleCreateNewPool = async () => {
    logEvent({
      eventName: "pool_creation_initiated",
      eventProperties: {},
    });
  };

  const handleOnViewPoolLinkClick = (id) => {
    logEvent({
      eventName: "pool_view_button_clicked",
      eventProperties: {
        pool_name: lendingData?.name,
      },
    });
    navigate(`/overview/${id}`);
  };

  const tableData = lendingData?.map((pool) => ({
    id: pool.id,
    name: pool.pool_name,
    apy: pool.apy.toFixed(2) + "%",
    tvl: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(pool.total_investment),
    riskRating: pool.risk_rating || "NaN",
  }));

  return isLendingLoading || isLoanStatisticsLoading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Loading />
    </div>
  ) : (
    <div className="container px-5 pt-2">
      <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
        <div className="col text-center text-md-start">
          <Typography variant="h1">Available Pools</Typography>
        </div>
        {/* <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
          <Button
            type="button"
            variant="secondary"
            disabled={true}
            // className="new-pool-button"
            fullWidth={false}
            // onClick={handleCreateNewPool}
          >
            Create New Pool
          </Button>
        </div> */}
      </div>
      <Typography variant="h3" className="pt-3 text-center text-md-start">
        Total
      </Typography>
      <TradeFiSummary data={loanStatisticsData?.data} />
      <PoolList
        tableData={tableData}
        handleOnViewPoolLinkClick={handleOnViewPoolLinkClick}
        handleCreateNewPool={handleCreateNewPool}
      />
    </div>
  );
}
