import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    profile: builder.query({
      query: () => ({
        url: '/api/auth/me/',
        method: 'GET'
      }),
      providesTags: ['Profile']
    }),
    inviteUser: builder.mutation({
      query: ({ invitee_email }) => ({
        url: '/api/tenants/invite/',
        method: 'POST',
        body: {
          invitee_email,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ first_name, last_name }) => ({
        url: '/api/tenants/users/',
        method: 'PUT',
        body: {
          first_name,
          last_name,
        },
      }),
    }),
    resendInviteUser: builder.mutation({
      query: ({ id }) => ({
        url: '/api/tenants/invite/',
        method: 'PUT',
        body: {
          id,
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: '/api/tenants/invite/',
        method: 'DELETE',
        body: {
          id,
        },
      }),
    }),
  }),
});

export const { useLazyProfileQuery, useProfileQuery, useInviteUserMutation, useUpdateUserMutation, useResendInviteUserMutation, useDeleteUserMutation } = userAPI;