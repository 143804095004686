/*
Current modal layout logic is as follows.

Modal Layout has three sections; Header, Body, Footer
Make sure to include them within the ModalLayout Component in the correct order. (check one of the example modals)
You can render anything within those sections.
Modal layout takes the full screen height available while leaving padding on the top and bottom.
Within the Layout, Modal Header and Footer has fixed heights. 
Modal Body takes up the full height available regardless of the content height.
If the content within the Body component is too long, body component will be internally scrollable to view everything. (check ExampleModalOne)

Alternatively you can use just the Modal Layout without these three components if you need flexibility.

You can change these behaviors according the UI/UX design of the project. Plan these changes behaviors beforehand. 
Make sure that the changes are consistent across project and works well in any screen.
Do not change these components without consulting senior frontend engineers (someone equal or above SSE)
*/

function ModalLayout({ children, width }) {
    return (
        <div
            className="modal-dialog modal-dialog-scrollable overflow-auto pb-4"
            style={{ width: width, maxHeight: "100vh" }}
        >
            <div className="modal-content h-100">{children}</div>
        </div>
    );
}

ModalLayout.Header = ({ children }) => (
    <div className="modal-header">
        <h5 className="modal-title w-100">{children}</h5>
    </div>
);

ModalLayout.Body = ({ children }) => (
    <div className="modal-body">{children}</div>
);

ModalLayout.Footer = ({ children }) => (
    <div className="modal-footer">{children}</div>
);

export default ModalLayout;
