import { useModal } from "../../hooks/modalsHooks";
// import Button from "../Button/Button";

import ModalLayout from "../modals/ModalLayout";

/**
 * Example modal component. Shown via `ModalContext`
 * @param {{
 * title: string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function SuccessModal({ type }) {
    const { hideModal } = useModal();

    const handleClose = () => {
        hideModal();
    };

    return (
        <ModalLayout width={600}>
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 d-flex justify-content-center">
                        <div>
                            <img
                                src="/icons/check.svg"
                                alt="Logo"
                                height="40px"
                                width="40px"
                            />
                        </div>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <p
                        className="text-center"
                        style={{ fontFamily: "Poppins", fontWeight: "600" }}
                    >
                        Successfully Created the {type}
                    </p>
                    <span
                        className="d-flex justify-content-center"
                        style={{ fontSize: "30px" }}
                    >
                        😊
                    </span>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="m-4 d-flex justify-content-center flex-column align-items-center">
                        {/* <Button variant="primary" onClick={handleClose}>
                            Awesome
                        </Button> */}

                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
