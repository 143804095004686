import React, { useState } from "react";
import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";
import "./model.scss";
import { useNavigate, useParams } from "react-router-dom";
import ModalLayout from "../modals/ModalLayout";
import QRCode from "react-qr-code";
import { logEvent } from "../../utils/amplitudeUtlis";

export default function TraceAssetModal({ assetLink }) {
    const { hideModal } = useModal();
    const navigate = useNavigate();

    const qrLink = `${window.location.origin}${assetLink}`;

    const handleClose = () => {
        hideModal();
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Trace Asset Modal Page",
            },
        });
    };

    const handleViewTrace = () => {
        navigate(assetLink);
        hideModal();
    };

    return (
        <ModalLayout width="100%" className="overflow-auto">
            <div className="card d-flex justify-content-center p-4">
                <ModalLayout.Header>
                    <Typography variant="h2">View record trace</Typography>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="row d-flex flex-wrap mt-2">
                        <QRCode value={qrLink} size={256} />
                        <div className="mt-2 d-flex justify-content-center"></div>
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="mt-4 d-flex justify-content-center flex-row align-items-center">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleViewTrace}
                        >
                            View trace
                        </button>
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-secondary me-2"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
