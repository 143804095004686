import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const renderCustomizedLabel = (props) => {
  const { x, y, width } = props;
  const strokeWidth = 3;

  return (
    <g>
      <line
        x1={x}
        y1={y}
        x2={x + width}
        y2={y}
        stroke="#6b8fa8"
        strokeWidth={strokeWidth}
      />
    </g>
  );
};

const StackedBarChart = ({ data, actualDataKey, projectedDataKey }) => {
  const [opacity, setOpacity] = React.useState({
    [actualDataKey]: 1,
    [projectedDataKey]: 1,
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip p-3 border rounded-3"
          style={{ backgroundColor: "white" }}
        >
          <p className="label fw-bold">{`${label}`}</p>
          {payload.map((entry, index) => (
            <p
              key={`item-${index}`}
              className="label"
              style={{ color: "#a3b9cc" }}
            >
              {`${
                entry.name === "projectedRepaymentValue"
                  ? "Projected Repayment Value"
                  : "Actual Repayment Value"
              } : ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="month"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: "#4F7A96",
              fontWeight: "bold",
              fontSize: "15px",
              fontFamily: "Arial",
              stroke: "none",
            }}
          />
          <YAxis hide={true} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            payload={[
              {
                value: "Projected Repayment Value",
                type: "square",
                color: "#a3b9cc",
                legendIcon: (
                  <svg width="40" height="40">
                    <rect
                      x="0"
                      y="0"
                      width="30"
                      height="30"
                      stroke="#a3b9cc"
                      strokeWidth="3"
                      fill="transparent"
                    />
                  </svg>
                ),
              },
              {
                value: "Actual Repayment Value",
                type: "square",
                color: "#a3b9cc",
                fill: "none",
              },
            ]}
          />
          <Bar
            dataKey={projectedDataKey}
            fill="#a3b9cc"
            fillOpacity={0}
            stroke="#a3b9cc"
            strokeWidth={2}
            barSize={60}
            stackId="a"
          >
            <LabelList
              dataKey={projectedDataKey}
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey={actualDataKey}
            fill="#a3b9cc"
            stroke="#a3b9cc"
            fillOpacity={opacity[actualDataKey]}
            barSize={60}
            stackId="a"
          >
            <LabelList
              dataKey={actualDataKey}
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;
