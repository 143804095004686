import React from "react";
import TradeFiOverviewCard from "../../../components/Card/tradeFiOverviewCard";

const TradeFiSummary = ({ data }) => {
  return (
    <div className="row d-flex flex-wrap mt-3">
      <div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap pb-3 pb-md-0">
        <TradeFiOverviewCard
          name="Active Loans"
          value={
            data?.total_approved_loan_amount
              ? `$${new Intl.NumberFormat().format(
                  data?.total_approved_loan_amount
                )}`
              : "$0.00"
          }
          value2={data?.active_loans_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap pb-3 pb-md-0">
        <TradeFiOverviewCard
          name="Average Interest Rate"
          value={
            data?.average_interest_rate
              ? `${data?.average_interest_rate}%`
              : "0%"
          }
          value2={data?.total_loss_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap pb-3 pb-md-0">
        <TradeFiOverviewCard
          name="Total Loans Repaid"
          value={
            data?.total_loans_repaid
              ? `$${new Intl.NumberFormat().format(data?.total_loans_repaid)}`
              : "$0.00"
          }
          value2={data?.total_loans_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap">
        <TradeFiOverviewCard
          name="Interest Repaid"
          value={
            data?.total_interest_repaid
              ? `$${new Intl.NumberFormat().format(
                  data?.total_interest_repaid
                )}`
              : "$0.00"
          }
          value2={data?.interest_repaid_percentage || "0"}
          variant="main"
        />
      </div>
    </div>
  );
};

export default TradeFiSummary;
