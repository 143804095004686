import React from "react";
import TradeFiOverviewCard from "../../../components/Card/tradeFiOverviewCard";

const PoolSummary = ({ data }) => {
  return (
    <div className="row d-flex flex-wrap mt-4">
      {/* <div className="col-12 col-sm d-flex flex-wrap pb-3 pb-md-0 flex-grow-1">
        <TradeFiOverviewCard
          name="Average APY"
          value={data?.average_apy || "0"}
          value2={data?.average_apy_percentage || "0"}
          variant="main"
        />
      </div> */}
      <div className="col-12 col-sm d-flex flex-wrap pb-3 pb-md-0 flex-grow-1">
        <TradeFiOverviewCard
          name="Active loan"
          value={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data?.total_approved_loan_amount || 0)}
          value2={data?.total_approved_loan_amount_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm d-flex flex-wrap pb-3 pb-md-0 flex-grow-1">
        <TradeFiOverviewCard
          name="Average interest rate"
          value={
            data?.average_interest_rate
              ? `${data?.average_interest_rate}%`
              : "0%"
          }
          value2={data?.average_interest_rate_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm d-flex flex-wrap pb-3 pb-md-0 flex-grow-1">
        <TradeFiOverviewCard
          name="Total Loans Repaid"
          value={
            data?.total_loans_repaid ? `${data?.total_loans_repaid}%` : "0%"
          }
          value2={data?.total_loans_repaid_percentage || "0"}
          variant="main"
        />
      </div>
      <div className="col-12 col-sm d-flex flex-wrap pb-3 pb-md-0 flex-grow-1">
        <TradeFiOverviewCard
          name="Interest Repaid"
          value={
            data?.total_interest_repaid
              ? `${data?.total_interest_repaid}%`
              : "0%"
          }
          value2={data?.total_interest_repaid_percentage || "0"}
          variant="main"
        />
      </div>
    </div>
  );
};

export default PoolSummary;
