import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
// import { useProfileQuery } from "../../services/userService";

export default function TradeFiAppLayout() {
  //   const { data: profileData } = useProfileQuery();
  //   let user = profileData?.data || null;
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <main style={{ paddingTop: "60px" }}>
          {/* //Todo: Add breadcrumbs */}
          {<Outlet />}
        </main>
      </div>
    </>
  );
}
