import React from "react";
import { BasicTable } from "../../../components/tables";

const LoanTab = ({ loansData }) => {
  const mappedLoansData = loansData
    ? loansData.map((loan) => ({
        name: loan?.lending_pool.pool_name,
        amount: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(loan?.amount || 0),
        interestRate: `${loan?.interest_rate}%`,
        cycle: `${loan?.term_in_days} days`,
        date: loan?.last_disbursement?.repayment_date
          ? new Date(loan?.last_disbursement?.repayment_date)
              .toISOString()
              .split("T")[0]
          : "",
        status: loan?.status === "CREATED" ? "ACTIVE" : loan?.status,
      }))
    : [];

  return (
    <div>
      <BasicTable
        data={mappedLoansData}
        columns={[
          {
            header: "Name",
            accessor: "name",
          },
          {
            header: "Amount",
            accessor: "amount",
          },
          {
            header: "Interest Rate",
            accessor: "interestRate",
          },
          {
            header: "Loan Cycle",
            accessor: "cycle",
          },
          {
            header: "Due Date",
            accessor: "date",
          },
          {
            header: "Status",
            accessor: "status",
            Cell: ({ cell: { value } }) => (
              <div className="status-cell d-md-flex align-items-md-center justify-content-md-center">
                {value}
              </div>
            ),
          },
        ]}
        isSortable={false}
        isPaginated={mappedLoansData?.length > 5}
        pageSize={5}
        currentPage={0}
        showCheckbox={false}
      />
    </div>
  );
};

export default LoanTab;
