import { ROUTES } from "../../config/routesConfig";
import { useRouting } from "../../hooks/routesHooks";
import "./navbar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { logEvent } from "../../utils/amplitudeUtlis";
import Typography from "../Typography/Typography";

export default function Navbar() {
  const { getRouteLink } = useRouting();
  const navigate = useNavigate();

  // const handleCreateNewProject = async () => {
  //     logEvent({
  //         eventName: "project_creation_initiated",
  //         eventProperties: {},
  //     });
  //     navigate("/start/token-service");
  //     // showModal({
  //     //     modalType: "CreateProjectModal",
  //     //     modalProps: {
  //     //         projectType: "token-service",
  //     //     },
  //     // });
  // };

  return (
    <nav className="navbar navbar-expand-lg navbar-tradeFi fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src="/images/hyperglade-logo-black.svg"
            className="ps-3 img-fluid "
            alt="logo"
          />
        </a>

        <button
          className="navbar-toggler py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarCollapse">
          <ul className="nav navbar-nav ms-auto d-flex align-items-lg-center">
            {/* {isOnboarded() && ( */}
            <>
              <li className="nav-item py-lg-0 py-2">
                <NavLink
                  className="nav-link active"
                  // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                  //     tabName: "company",
                  // })}
                >
                  Dashboard
                </NavLink>
              </li>

              <li className="nav-item py-lg-0 py-2">
                <NavLink
                  className="nav-link active"
                  to="https://hyperglade.com/contact-hyperglade/"
                  // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                  //     tabName: "company",
                  // })}
                >
                  Lend
                </NavLink>
              </li>
              <li className="nav-item py-lg-0 py-2">
                <NavLink
                  className="nav-link active"
                  to="https://hyperglade.com/contact-hyperglade/"
                  // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                  //     tabName: "company",
                  // })}
                >
                  Borrow
                </NavLink>
              </li>
              {/* <li className="nav-item py-lg-0 py-2">
                <NavLink
                  className="nav-link active"
                  to="https://hyperglade.com/contact-hyperglade/"
                  // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                  //     tabName: "company",
                  // })}
                >
                  Trade
                </NavLink>
              </li>

              <li className="nav-item px-2">
                <Button
                  fullWidth={false}
                  disabled={true}
                  // onClick={handleCreateNewProject}
                >
                  Connect
                </Button>
              </li> */}
              <li className="nav-item dropdown pe-3 px-2 py-2">
                <a
                  className="d-flex flex-row align-items-center"
                  href=" "
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {/* <div className="initial-avatar">
                    <span>
                      {user && user.first_name && user.last_name
                        ? `${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`
                        : "T"}
                    </span>
                  </div> */}
                </a>

                <div className="m-2 dropdown-menu dropdown-menu-end">
                  {/* <NavLink
                                            className="dropdown-item"
                                            to="/settings/company"
                                        >
                                            <p
                                                className="m-1"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#navbarCollapse"
                                                aria-expanded="false"
                                                aria-controls="navbarCollapse"
                                            >
                                                Profile
                                            </p>
                                        </NavLink> */}
                  <NavLink
                    className="dropdown-item"
                    // to={getRouteLink(ROUTES.SIGNOUT)}
                  >
                    <p
                      className="m-2"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarCollapse"
                      aria-expanded="false"
                      aria-controls="navbarCollapse"
                    >
                      <Button
                        variant="dropdown"
                        icon="/icons/logout.svg"
                        className="w-100 my-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-expanded="false"
                        aria-controls="navbarCollapse"
                        style={{
                          backgroundColor: "#T1C8AD1",
                        }}
                      >
                        Sign out
                      </Button>
                    </p>
                  </NavLink>
                </div>
              </li>
            </>
            {/* )} */}
            {/* {!isLoggedIn() && (
              <>
                <div className="mx-3 d-flex flex-row align-items-center">
                  <li className="nav-item">
                    <NavLink
                      className="m-auto nav-link"
                      to={getRouteLink(ROUTES.SIGNIN)}
                    >
                      Sign in
                    </NavLink>
                  </li>
                </div>
                <li className="nav-item">
                  <Button
                    type={"button"}
                    variant="primary"
                    fullWidth={false}
                    onClick={() => navigate(getRouteLink(ROUTES.SIGNIN))}
                  >
                    Register now
                  </Button>
                </li>
              </>
            )} */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
