import NETWORKS from "./networks.json";
import * as Chains from "@thirdweb-dev/chains";

for (const chainId in NETWORKS) {
    let data;
    switch (chainId) {
        case "11155111":
            data = Chains.Sepolia;
            break;
        case "80001":
            data = Chains.Mumbai;
            break;
        case "137":
            data = Chains.Polygon;
            break;
        default:
            break;
    }
    NETWORKS[chainId].thirdWebData = data
}

export { NETWORKS };
