import { useMemo } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import BasicInfo from "./BasicInfo";
import BarChartComponent from "../../../components/Chart/BarChart";
import "./tradeFiPoolDetail.scss";

import BorrowersTab from "./BorrowersTab";
import LoanTab from "./LoanTab";
import Tab from "../../../components/tradeFinance/Tab/Tab";
import PoolSummary from "./PoolSummary";
import {
  useGetAllBorrowersInfoQuery,
  useGetAllRecentActivityQuery,
  useGetGraphDataQuery,
  useGetLendingPoolByIdQuery,
  useGetLoansInLendingPoolQuery,
  useGetOverviewOfAPoolQuery,
} from "../../../services/tradeFiService";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { useState } from "react";
import StackedBarChart from "../../../components/Chart/StackedBarChart";
import RecentActivity from "./RecentActivity";

const TradeFiPoolDetailPage = () => {
  const { poolId } = useParams();
  const navigate = useNavigate();
  const { data: basicInfo, isLoading: isBasicInfoLoading } =
    useGetLendingPoolByIdQuery(poolId);

  const { data: poolOverview, isLoading: isPoolOverviewLoading } =
    useGetOverviewOfAPoolQuery(poolId);
  const { data: graphData, isLoading: isGraphDataLoading } =
    useGetGraphDataQuery({ id: poolId, year: 2024 });

  const { data: loansData, isLoading: isLoanLoading } =
    useGetLoansInLendingPoolQuery(poolId);

  const { data: borrowersData, isLoading: isBorrowersLoading } =
    useGetAllBorrowersInfoQuery(poolId);

  const { data: recentActivityData, isLoading: isRecentActivityLoading } =
    useGetAllRecentActivityQuery(poolId);

  /** TODO: Refactor this out later */
  const totalLoan = useMemo(() => {
    if (loansData?.data && loansData.data.length > 0) {
      return loansData.data[0].amount;
    }
  }, [loansData]);
  /** TODO */

  const tabsData = [
    {
      path: "borrowers",
      name: "Borrowers",
      component: <BorrowersTab borrowersData={borrowersData?.data} />,
    },
    {
      path: "load",
      name: "Loans",
      component: <LoanTab loansData={loansData?.data} />,
    },
  ];
  // Map the response data to the format your component expects
  const graphDataMapping = graphData?.data?.graph_data?.map((item) => ({
    month: item.month_name.substring(0, 3), // Get the first 3 letters of the month
    actualRepaymentValue: item.actual_payment,
    projectedRepaymentValue: item.projected_payment,
  }));
  if (
    isBasicInfoLoading ||
    isPoolOverviewLoading ||
    isGraphDataLoading ||
    isLoanLoading ||
    isBorrowersLoading ||
    isRecentActivityLoading
  ) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="container px-5 pt-2">
      <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
        <div className="col text-center text-md-start">
          <Typography variant="h1">{basicInfo?.data?.pool_name}</Typography>
        </div>
        <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
          <Button
            type="button"
            variant="secondary"
            fullWidth={false}
            onClick={() => navigate("/overview")}
          >
            Back to dashboard
          </Button>
        </div>
      </div>
      <BasicInfo
        description={basicInfo?.data?.description}
        totalValue={new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(basicInfo?.data?.total_investment)}
        apy={`${basicInfo?.data?.apy}%`}
        riskRating={basicInfo?.data?.risk_rating}
        countryRating={basicInfo?.data?.country_rating}
        liquidity={basicInfo?.data?.liquidity}
        totalLoan={new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(totalLoan)}
      />
      <div className="row pt-5">
        <div className="col-12 d-flex flex-wrap ">
          <Typography variant="h3" className="m-0">
            Overview
          </Typography>
        </div>
        <PoolSummary data={poolOverview?.data} />
      </div>
      <div className="row pt-4">
        <div className="col-12 pt-4">
          <div className="card chart-card">
            <div className="card-body">
              <Typography variant="h4" className="py-2">
                Repayment Schedule
              </Typography>
              <Typography variant="h5" className="">
                2024
              </Typography>
              <StackedBarChart
                data={graphDataMapping}
                actualDataKey="actualRepaymentValue"
                projectedDataKey="projectedRepaymentValue"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <RepaymentList data={graphData?.data?.loan_repayments} /> */}
      <div className="row py-4">
        <div className="col-12 d-flex flex-wrap ">
          <Typography variant="h3" className="m-0">
            Portfolio
          </Typography>
        </div>
        <div className="col-12 pt-3">
          <Tab tabsData={tabsData} tabType="pills" defaultTab={0} />
        </div>
      </div>
      <div className="row py-4">
        <div className="col-12 d-flex flex-wrap ">
          <Typography variant="h3" className="m-0">
            Recent Activity
          </Typography>
        </div>
        <div className="col-12 pt-3">
          <RecentActivity recentActivity={recentActivityData?.data} />
        </div>
      </div>
    </div>
  );
};

export default TradeFiPoolDetailPage;
