export const Icon = ({
    className,
    src,
    alt,
    height,
    width,
    color,
    onClick,
}) => {
    return (
        <>
            <img
                className={className}
                src={src}
                alt={alt}
                height={height}
                width={width}
                onClick={onClick}
                style={{
                    color: color,
                    cursor: onClick ? "pointer" : "default",
                }}
            />
        </>
    );
};
