import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { tradeFiAPI } from "./services/tradeFiService";


const combinedReducer = combineReducers({
    [tradeFiAPI.reducerPath]: tradeFiAPI.reducer,
});

// we structure store like this so that we can easily reset the store on logout.
const rootReducer = (state, action) => {
    if (action.type === "auth/logout") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    //add rtkq middleware to below array
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(tradeFiAPI.middleware),
    devTools: true,
});
