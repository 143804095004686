import React from "react";
import ModalLayout from "../modals/ModalLayout";
import Button from "../Button/Button";
import Typography from "../Typography/Typography";

const PopUpModal = ({
    isOpen,
    handleClose,
    title,
    message,
    type,
    handleContinue,
}) => {
    return (
        <ModalLayout width={600} show={isOpen} onHide={handleClose}>
            <div
                className="card d-flex justify-content-center"
                style={{ borderRadius: "10px" }}
            >
                {" "}
                <ModalLayout.Header>
                    <div className="w-100 p-4 d-flex justify-content-center">
                        <div>
                            <img
                                src={
                                    type === "error"
                                        ? "/icons/error.png"
                                        : type === "settings"
                                        ? "/icons/settings-icon.svg"
                                        : "/icons/check.svg"
                                }
                                alt="Logo"
                                height="40px"
                                width="40px"
                            />
                        </div>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <Typography variant="h3" className="text-center">
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        className="text-center pt-2 px-3"
                    >
                        {message}
                    </Typography>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="m-4 d-flex justify-content-center flex-row align-items-center">
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-secondary mx-2"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-primary mx-2"
                            onClick={handleContinue}
                        >
                            Continue
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
};

export default PopUpModal;
