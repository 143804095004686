import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";

import ModalLayout from "../modals/ModalLayout";

/**
 * Example modal component. Shown via `ModalContext`
 * @param {{
 * title: string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function ContactUSModal({ name }) {
    const { hideModal } = useModal();

    const handleClose = () => {
        hideModal();
    };

    return (
        <ModalLayout className="modal-dialog-centered">
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 my-4 d-flex justify-content-center">
                        <Typography variant="display3" className="text-center">
                            Get in touch to configure your Supply Chain.
                        </Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="w-100 mb-4 px-4 d-flex justify-content-center flex-column align-items-center">
                        <Typography variant="body1" className="text-center">
                            We are here to help you get started in bringing your
                            supply chain traceability online. Contact us for a
                            quick chat on next steps.
                        </Typography>
                        <div>
                            <a
                                href="https://hyperglade.com/contact/"
                                target="_blank"
                                className="btn btn-primary"
                                rel="noreferrer"
                            >
                                Contact Us
                            </a>
                        </div>
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    {/* <div className="m-4 d-flex justify-content-center flex-column align-items-center">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                    </div> */}
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
