import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";
import ModalLayout from "../modals/ModalLayout";
import "./model.scss";
import { useNavigate } from "react-router-dom";
// import   from "../forms";
import Form from "../forms/Form";
import TextField from "../forms/TextField";

/**
 * Example modal component. Shown via `ModalContext`
 * @param {{
 * title: string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function EditModal({ title = "Edit Node" }) {
    const { hideModal } = useModal();

    const navigate = useNavigate();
    const handleClose = () => {
        hideModal();
    };

    const handleSelectAndContinue = () => {
        hideModal();
        // navigate("/createproject");
    };

    return (
        <ModalLayout className="overflow-auto">
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 pb-0 d-flex justify-content-between">
                        <Typography variant="h2">{title}</Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="mx-3 mt-1 row d-flex flex-wrap">
                        <Form
                            initialValues={{
                                nodeUsers: "",
                                nodeTitle: "",
                                totalFundingAmount: "",
                            }}
                            // onSubmit={handleAddProjectSubmit}
                            // enableReinitialize={true}
                            // validationSchema={validationSchema}
                        >
                            <div className="w-100 mt-3">
                                <TextField
                                    id="nodeTitle"
                                    name="nodeTitle"
                                    label="Node title"
                                    style={{ width: "100%" }}
                                />
                            </div>

                            <div className="mt-3">
                                <TextField
                                    id="nodeUsers"
                                    name="Node Users"
                                    label="Node users"
                                    style={{ width: "100%" }}
                                    placeholder="Search users"
                                />
                            </div>

                            {/* {errorMessage && (
                                <div className="error-message">
                                    {errorMessage}
                                </div>
                            )} */}
                        </Form>
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="m-4 d-flex justify-content-center flex-row align-items-center">
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-secondary mx-2"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-primary mx-2"
                            onClick={handleSelectAndContinue}
                        >
                            Select and continue
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
