import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { BasicTable } from "../../../components/tables";
import "./tradeFiOverview.scss";
import { formatRisk } from "../../../utils/projectUtils";

const PoolList = ({
  tableData,
  handleOnViewPoolLinkClick,
  handleCreateNewPool,
}) => {
  return (
    <div className="row py-4">
      <div className="col-12">
        {tableData?.length > 0 ? (
          <BasicTable
            data={tableData}
            columns={[
              {
                header: "Name",
                accessor: "name",
              },
              {
                header: "APY",
                accessor: "apy",
                Cell: ({ value }) => {
                  return <div className="apy-component">{value}</div>;
                },
              },
              {
                header: "TVL",
                accessor: "tvl",
                Cell: ({ value }) => {
                  return (
                    <div className="tvl-component d-md-flex align-items-md-center">
                      <span className="tvl-label">{value}</span>
                    </div>
                  );
                },
              },
              {
                header: "Risk Rating",
                accessor: "riskRating",
                Cell: ({ value }) => {
                  return (
                    <div className="risk-rating-component d-md-flex align-items-md-center">
                      <div className="progress risk-rating-progress">
                        <div
                          className="progress-bar progress-bar-responsive"
                          role="progressbar"
                          style={{
                            width: `${value}%`,
                          }}
                          aria-valuenow={value}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <span className="risk-value">{formatRisk(value)}</span>
                    </div>
                  );
                },
              },
              {
                header: "Actions",
                accessor: "actions",
                Cell: ({ row: { original } }) => (
                  <div
                    className="link-button"
                    onClick={() => handleOnViewPoolLinkClick(original.id)}
                  >
                    View
                  </div>
                ),
              },
            ]}
            isSortable={false}
            isPaginated={tableData?.length > 5}
            pageSize={5}
            currentPage={0}
            showCheckbox={false}
          />
        ) : (
          <div className="d-flex flex-column align-items-center text-center pt-3">
            <img src="/images/placeholderImage.svg" alt="Placeholder" />
            <Typography variant="h3" className="pt-3">
              Start Your First Pool
            </Typography>
            <Typography variant="body1" className="pt-2">
              You haven't started any pools yet. Click the button below to start
              your first trade finance pool.
            </Typography>
            <Button
              className="start-pool-button"
              fullWidth={false}
              onClick={handleCreateNewPool}
            >
              Start Pool
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolList;
