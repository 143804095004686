import React from "react";
import { BasicTable } from "../../../components/tables";
import "./tradeFiPoolDetail.scss";

const RecentActivity = ({ recentActivity }) => {
  console.log(recentActivity, "recent activity");

  const mappedLoansData = recentActivity.map((activity) => ({
    contract: activity.contract_address,
    type: activity.type,
    date: new Date(activity.date).toISOString().split("T")[0],
    transaction: activity.explorer_url,
  }));

  return (
    <div>
      <BasicTable
        data={mappedLoansData}
        columns={[
          {
            header: "Contract",
            accessor: "contract",
            Cell: ({ value }) => (
              <div className="truncate" title={value}>
                {value}
              </div>
            ),
          },
          {
            header: "Type",
            accessor: "type",
          },
          {
            header: "Date",
            accessor: "date",
          },
          {
            header: "Transaction Link",
            accessor: "transaction",
            Cell: ({ value }) => (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="link-button"
              >
                View
              </a>
            ),
          },
        ]}
        isSortable={false}
        isPaginated={mappedLoansData?.length > 5}
        pageSize={5}
        currentPage={0}
        showCheckbox={false}
      />
    </div>
  );
};

export default RecentActivity;
