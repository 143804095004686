import PublicOnly from "../components/routing/PublicOnlyRoute";
import TradeFiAppLayout from "../pages/tradeFinance/TradeFiAppLayout";
import TradeFiOverviewPage from "../pages/tradeFinance/TradeFiOverview/TradeFiOverview";
import TradeFiPoolDetailPage from "../pages/tradeFinance/tradeFiPoolDetailPage/tradeFiPoolDetailPage";
// import TradeFiPoolDetailPage from "../pages/tradeFinance/tradeFiPoolDetailPage/tradeFiPoolDetailPage";

import { getRouteObj } from "../utils/routeUtils";

export const routesConfig = [
    {
        title: "Dashboard",
        path: "/",
        element: <TradeFiAppLayout />,
        routeId: "TRADE_FI_APP_LAYOUT",
        children: [
            {
                title: "Overview",
                path: "",
                element: <TradeFiOverviewPage />,
                routeId: "TRADE_FI_OVERVIEW",
            },
        ]
    },
    {
        title: "",
        path: "/",
        element: <TradeFiAppLayout />,
        routeId: "TRADE_FI_APP_LAYOUT",
        children: [
            {
                title: "Overview",
                path: "overview",
                element: (
                    <PublicOnly>
                        <TradeFiOverviewPage />
                    </PublicOnly>
                ),
                routeId: "TRADE_FI_OVERVIEW",
            },
            {
                title: "Landing",
                path: "overview/:poolId",
                element: (
                    <PublicOnly>
                        <TradeFiPoolDetailPage />
                    </PublicOnly>
                ),
                routeId: "TRADE_FI_DETAIL_PAGE",
            }
        ]

    },


    // {
    //     title: "SignIn",
    //     path: "/signin",
    //     element: (
    //         <PublicOnly>
    //             <SignInPage />
    //         </PublicOnly>
    //     ),
    //     routeId: "SIGNIN",
    // },
    // {
    //     title: "Register",
    //     path: "/register",
    //     element: (
    //         <PublicOnly>
    //             <SignUpPage />
    //         </PublicOnly>
    //     ),
    //     routeId: "SIGNUP",
    // },
    // {
    //     title: "Signout",
    //     path: "/logout",
    //     element: <SignoutPage />,
    //     routeId: "SIGNOUT",
    // },
];

// Returns Route ID Dictionary
export const ROUTES = getRouteObj(routesConfig);
